import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface ISectionHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const SectionHeader = ({ children, className }: ISectionHeaderProps) => {
  const sectionHeaderStyle = classNames(className, styles.sectionHeader);

  return (
    <h2 className={sectionHeaderStyle}>
      <span>{children}</span>
    </h2>
  );
};

export default SectionHeader;
